import * as React from "react"
import {Parallax} from "react-parallax"

const BannerImage = ({height, ident, image, children}) => {
  const calculatedHeight = height || 400

  return (
    <Parallax bgImage={image} bgImageAlt="EnginuityUSA" strength={250}>
      <div style={{height: `${calculatedHeight}px`}}>
        {children}
      </div>
    </Parallax>
  )
}

export default BannerImage
