export const specialities = [
  "Office",
  "Commercial Buildings",
  "Retirement & Aged Care",
  "Banking & Financial",
  "Retail Factories & Warehouses",
  "Storage Facilities",
  "New Construction",
  "Medical",
  "Entertainment Venues",
  "Restaurants",
  "Churches",
  "Residential Buildings",
  "Industrial Projects",
  "Childcare & Educational",
  "Government Projects",
]
