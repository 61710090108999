import * as React from "react"
import EnginuityUsaLink from "../components/enginuity_usa_link"

export const homeCards = [
  {
    title: "Design - Build",
    path: "/services"
  },
  {
    title: "Build",
    path: "/services"
  },
  {
    title: "Project Management",
    path: "/services"
  }
]
