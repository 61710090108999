import * as React from "react"
import Layout from "../components/layout"
import {
  description,
  cardTitle,
  cardContent,
  divider,
  services,
  emphatic,
  servicesTitle,
  bannerOverlay,
  bannerOverlayLower,
  bannerIdent,
  bannerIdentLower
} from "../css/index.module.css"
import { homeCards } from "../data/home_cards"
import { specialities } from "../data/specialities"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BrandedDivider from "../components/branded_divider"
import BannerImage from "../components/banner_image"
import banner from "../images/home.jpg"
import lowerBanner from "../images/engineering.jpg"
import { SEO } from "../components/seo"
import { Link } from "gatsby";


const IndexPage = () => {

  return (
    <Layout pageTitle="Home" noHeader={true}>
      <BannerImage image={banner}>
        <div className={bannerOverlay}><></>
        </div>
        <div className={bannerIdent}>
          Creativity | Design | Engineering | Contracting
        </div>
      </BannerImage>

      <div className={description}>
        <Container className="py-5">
          <Row>
            <p>
              Enginuity Construction, LLC is a
              Florida Certified General
              Contractor (CGC057601).
            </p>
            <p>
              We are a Veteran Owned Business.
            </p>
            <div className="d-flex justify-content-center mt-3">
              <div className={divider}/>
            </div>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="gx-lg-5 my-4">
          {homeCards.map(card => {
            return (
              <Col key={card.title} className="my-4" lg={true}>
                <h5 className={cardTitle} style={{textAlign: "center"}}>
                  <Link to={card.path}>{card.title}</Link>
                </h5>
              </Col>
            )
          })}
        </Row>
      </Container>

      <BannerImage image={lowerBanner}>
        <div className={bannerOverlayLower}><></>
        </div>
        <div className={bannerIdentLower}>
          Your Vision - Realized
        </div>
      </BannerImage>

      <div className={services}>
        <Container className="py-5">
          <h5 className={servicesTitle}>We offer a complete solution across all sectors</h5>
          <div className="mb-4">
            <small>areas already served include</small>
          </div>
          {specialities.map(specialty => {
            return (
              <span>{specialty}<BrandedDivider/></span>
            )
          })}
        </Container>
      </div>
    </Layout>
  )
}

export const Head = () => <SEO title={"Enginuity GC | Home"}/>

export default IndexPage

